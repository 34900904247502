import { LICENSE_KEY_FACETEC, API_BEMSIGN_URL } from 'services/consts';

export const ZoomGlobalState = (function () {
    var DeviceLicenseKeyIdentifier = LICENSE_KEY_FACETEC;

    // var DeviceLicenseKeyIdentifier = 'dm8KkWyVQy8XEvVVqZvGGgCxeFestPiF';

    var ZoomServerBaseURL = `${API_BEMSIGN_URL}/Facetec`;
    // var ZoomServerBaseURL = `https://liveness-hml.bempromotora.com.br`;
    // var ZoomServerBaseURL = 'https://api.facetec.com/api/v3.1/biometrics';
    // The customer-controlled public key used during encryption of FaceMap data.
    // Please see https://dev.zoomlogin.com/zoomsdk/#/licensing-and-encryption-keys for more information.
    var PublicFaceMapEncryptionKey = "-----BEGIN PUBLIC KEY-----\n" +
        "MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA5PxZ3DLj+zP6T6HFgzzk\n" +
        "M77LdzP3fojBoLasw7EfzvLMnJNUlyRb5m8e5QyyJxI+wRjsALHvFgLzGwxM8ehz\n" +
        "DqqBZed+f4w33GgQXFZOS4AOvyPbALgCYoLehigLAbbCNTkeY5RDcmmSI/sbp+s6\n" +
        "mAiAKKvCdIqe17bltZ/rfEoL3gPKEfLXeN549LTj3XBp0hvG4loQ6eC1E1tRzSkf\n" +
        "GJD4GIVvR+j12gXAaftj3ahfYxioBH7F7HQxzmWkwDyn3bqU54eaiB7f0ftsPpWM\n" +
        "ceUaqkL2DZUvgN0efEJjnWy5y1/Gkq5GGWCROI9XG/SwXJ30BbVUehTbVcD70+ZF\n" +
        "8QIDAQAB\n" +
        "-----END PUBLIC KEY-----";
    // You can paste the license text here, while preserving line breaks.
    // If SFTPLicenseText is null, it will attempt to initialize via FaceTec https
    var SFTPLicenseText = null;

    // Used for bookkeeping around demonstrating enrollment/authentication functionality of ZoOm.
    var randomUsername = "";
    var isRandomUsernameEnrolled = false;

    function retrieveConfigurationWizardCustomization(FaceTecSDK) {
        var sdkImageDirectory = "./core-sdk/FaceTec_images/";

        // For Color Customization
        var outerBackgroundColor = "#f2f2f3";
        var frameColor = "#fff";
        var borderColor = "#fff";
        var ovalColor = "#9e9e9e";
        var dualSpinnerColor = "#fff";
        var textColor = "#212121";
        var buttonAndFeedbackBarColor =  "#0a46ff";
        var buttonAndFeedbackBarTextColor = "#fff";
        var buttonColorPressed = "#0a46ff";
        var buttonBackgroundDisabled = '#eeeeee'
        var colorTextButtonDisabled = '#9e9e9e';
        var font = 'Inter, sans-serif';

        // For Frame Corner Radius Customization
        let frameCornerRadius = "20px";

        // For Cancel Button Customization
        var cancelButtonImage = sdkImageDirectory + "FaceTec_cancel.png";
        var cancelButtonLocation = FaceTecSDK.FaceTecCancelButtonLocation.Disabled;

        // For image Customization
        var yourAppLogoImage = sdkImageDirectory + "FaceTec_your_app_logo.png";
        var securityWatermarkImage = FaceTecSDK.FaceTecSecurityWatermarkImage.FaceTec;

        // For session time
        var customTimeLiveness = 60;
        var customTimeIdScan = 180;

        // Set a default customization
        var defaultCustomization = new FaceTecSDK.FaceTecCustomization();

        // Set session time
        defaultCustomization.sessionTimerCustomization.livenessCheckNoInteractionTimeout = customTimeLiveness;
        defaultCustomization.sessionTimerCustomization.idScanNoInteractionTimeout = customTimeIdScan;


        // Set Frame Customization
        defaultCustomization.frameCustomization.borderCornerRadius = frameCornerRadius;
        defaultCustomization.frameCustomization.backgroundColor = frameColor;
        defaultCustomization.frameCustomization.borderColor = borderColor;

        // Set Overlay Customization
        defaultCustomization.overlayCustomization.brandingImage = yourAppLogoImage;
        defaultCustomization.overlayCustomization.backgroundColor = outerBackgroundColor;
        defaultCustomization.overlayCustomization.showBrandingImage = false;

        // Set Guidance Customization
        defaultCustomization.guidanceCustomization.backgroundColors = frameColor;
        defaultCustomization.guidanceCustomization.foregroundColor = textColor;
        defaultCustomization.guidanceCustomization.buttonBackgroundNormalColor = buttonAndFeedbackBarColor;
        defaultCustomization.guidanceCustomization.buttonBackgroundDisabledColor = buttonBackgroundDisabled;
        defaultCustomization.guidanceCustomization.buttonBackgroundHighlightColor = buttonColorPressed;
        defaultCustomization.guidanceCustomization.buttonTextNormalColor = buttonAndFeedbackBarTextColor;
        defaultCustomization.guidanceCustomization.buttonTextDisabledColor = colorTextButtonDisabled;
        defaultCustomization.guidanceCustomization.buttonTextHighlightColor = buttonAndFeedbackBarTextColor;
        defaultCustomization.guidanceCustomization.retryScreenImageBorderColor = borderColor;
        defaultCustomization.guidanceCustomization.retryScreenOvalStrokeColor = borderColor;
        defaultCustomization.guidanceCustomization.buttonFont = font;
        defaultCustomization.guidanceCustomization.headerFont = font;
        defaultCustomization.guidanceCustomization.subtextFont = font;

        // Set Oval Customization
        defaultCustomization.ovalCustomization.strokeColor = ovalColor;
        defaultCustomization.ovalCustomization.progressColor1 = dualSpinnerColor;
        defaultCustomization.ovalCustomization.progressColor2 = dualSpinnerColor;

        // Set Feedback Customization
        defaultCustomization.feedbackCustomization.backgroundColor = buttonAndFeedbackBarColor;
        defaultCustomization.feedbackCustomization.textColor = buttonAndFeedbackBarTextColor;
        defaultCustomization.feedbackCustomization.textFont = font;

        // Set Cancel Customization
        defaultCustomization.cancelButtonCustomization.customImage = cancelButtonImage;
        defaultCustomization.cancelButtonCustomization.location = cancelButtonLocation;

        // Set Security Watermark Customization
        defaultCustomization.securityWatermarkCustomization.setSecurityWatermarkImage(securityWatermarkImage);

        // Set Result Screen Customization
        defaultCustomization.resultScreenCustomization.backgroundColors = frameColor;
        defaultCustomization.resultScreenCustomization.foregroundColor = textColor;
        defaultCustomization.resultScreenCustomization.activityIndicatorColor = buttonAndFeedbackBarColor;
        defaultCustomization.resultScreenCustomization.resultAnimationBackgroundColor = buttonAndFeedbackBarColor;
        defaultCustomization.resultScreenCustomization.resultAnimationForegroundColor = buttonAndFeedbackBarTextColor;
        defaultCustomization.resultScreenCustomization.uploadProgressFillColor = buttonAndFeedbackBarColor;

        this.currentCustomization = defaultCustomization;

        // Set ID Scan Customization
        defaultCustomization.idScanCustomization.selectionScreenBackgroundColors = frameColor;
        defaultCustomization.idScanCustomization.selectionScreenForegroundColor = textColor;
        defaultCustomization.idScanCustomization.reviewScreenBackgroundColors = frameColor;
        defaultCustomization.idScanCustomization.reviewScreenForegroundColor = buttonAndFeedbackBarTextColor;
        defaultCustomization.idScanCustomization.reviewScreenTextBackgroundColor = buttonAndFeedbackBarColor;
        defaultCustomization.idScanCustomization.captureScreenForegroundColor = buttonAndFeedbackBarTextColor;
        defaultCustomization.idScanCustomization.captureScreenTextBackgroundColor = buttonAndFeedbackBarColor;
        defaultCustomization.idScanCustomization.buttonBackgroundNormalColor = buttonAndFeedbackBarColor;
        defaultCustomization.idScanCustomization.buttonBackgroundDisabledColor = buttonColorPressed;
        defaultCustomization.idScanCustomization.buttonBackgroundHighlightColor = buttonColorPressed;
        defaultCustomization.idScanCustomization.buttonTextNormalColor = buttonAndFeedbackBarTextColor;
        defaultCustomization.idScanCustomization.buttonTextDisabledColor = buttonAndFeedbackBarTextColor;
        defaultCustomization.idScanCustomization.buttonTextHighlightColor = buttonAndFeedbackBarTextColor;
        defaultCustomization.idScanCustomization.captureScreenBackgroundColor = frameColor;
        defaultCustomization.idScanCustomization.captureFrameStrokeColor = borderColor;
        defaultCustomization.idScanCustomization.showSelectionScreenBrandingImage = false;
        defaultCustomization.idScanCustomization.showSelectionScreenDocumentImage = true;
        defaultCustomization.idScanCustomization.buttonFont = font;

        // Set Initial Loading Customization
        defaultCustomization.initialLoadingAnimationCustomization.backgroundColor = buttonAndFeedbackBarTextColor;
        defaultCustomization.initialLoadingAnimationCustomization.foregroundColor = buttonAndFeedbackBarColor;

        // Set Vocal Guidance
        defaultCustomization.vocalGuidanceCustomization.mode = 1;

        // Set Initial Loading Animation Customization
        defaultCustomization.initialLoadingAnimationCustomization.messageFont = font;

        return defaultCustomization;
    };

    var wasSDKConfiguredWithConfigWizard = true;

    var currentCustomization;
    return {
        DeviceLicenseKeyIdentifier: DeviceLicenseKeyIdentifier,
        ZoomServerBaseURL: ZoomServerBaseURL,
        PublicFaceMapEncryptionKey: PublicFaceMapEncryptionKey,
        randomUsername: randomUsername,
        isRandomUsernameEnrolled: isRandomUsernameEnrolled,
        SFTPLicenseText: SFTPLicenseText,
        currentCustomization,
        retrieveConfigurationWizardCustomization,
        wasSDKConfiguredWithConfigWizard
    };
})();
