const responseCompleto = {
    data: {
        retorno: {
            id: "string",
            dataCriacao: "2023-04-04T12:07:17.165Z",
            empresaId: "string",
            layout: {
                telas: [
                    {
                        nome: "passos",
                        corFundo: "#0B45E4",
                        corFonte: null,
                        corIcone: "#FFFFFF",
                        corBotaoPrimario: '#FAFAFF',
                        corFonteBotaoPrimario: '#0A46FF',
                        textos: [
                            {
                                nome: "titulo",
                                texto: "Olá, precisamos validar sua identidade"
                            },
                            {
                                nome: "subtitulo",
                                texto: ""
                            },
                            {
                                nome: "info_adicional",
                                texto: `Esta ferramenta é desenvolvida e fornecida por BEM PROMOTORA DE VENDAS E SERVIÇOS S/A a sua utilização pelo usuário está submetida a termos de uso e privacidade próprios. <a href="https://www.bempromotora.com.br/bemsign" target="_blank" rel="noopener noreferrer">Acesse para saber mais</a>.`
                            }
                        ]
                    },
                    {
                        nome: "acesso",
                        corFundo: "#F2F2F3",
                        corFonte: "#000050",
                        corIcone: '#0B45E4',
                        corBotaoPrimario: '#0A46FF',
                        corFonteBotaoPrimario: '#FAFAFF',
                    },
                    {
                        nome: "camera",
                        corFundo: "#F2F2F3",
                        corIcone: '#0B45E4',
                    },
                    {
                        nome: "localizacao",
                        corFundo: "#F2F2F3",
                        corIcone: '#0B45E4',
                    },
                    {
                        nome: "verificacao",
                        corFundo: "#F2F2F3",
                        corBotaoPrimario: '#0A46FF',
                        corFonteBotaoPrimario: '#FAFAFF',
                    },
                    {
                        nome: "confirmacao",
                        corFundo: "#F2F2F3",
                        corFundoSecundaria: "#0A46FF",
                        corBotaoPrimario: '#0A46FF',
                        corFonteBotaoPrimario: '#FAFAFF',
                    },
                    {
                        nome: "conclusao",
                        corFundo: "#0B45E4",
                        corFonte: "#FFFFFF",
                    },
                    {
                        nome: "aviso",
                        corFundo: "#0B45E4",
                        corBotaoPrimario: '#FAFAFF',
                        corFonteBotaoPrimario: '#0A46FF',
                    },
                    {
                        nome: "biometria",
                        corFundo: "#0B45E4",
                    },
                    {
                        nome: "navegador",
                        corFundo: "#0B45E4",
                        corBotaoPrimario: '#0B45E4',
                    },
                    {
                        nome: "tentativa",
                        corFundo: "#0B45E4",
                    },
                    {
                        nome: "header",
                        corFundo: "#0B45E4",
                    },

                ]
            }
        }
    }
};

const responseNulo = {
    data: {
        retorno: null
    }
}

export { responseCompleto, responseNulo };